import {UsersPickerProps} from "./usersPickerProps";
import {Dropdown} from "primereact/dropdown";
import {useAuth} from "../../../../core/hooks";
import {useEffect, useState} from "react";
import {WorkspaceUser} from "../../../../core/contexts/User/types";
import {User} from "@phosphor-icons/react";

const UserPicker = (props: UsersPickerProps) => {

    const { userAttributes } = useAuth();
    const [selectedUser, setSelectedUser] = useState({ name: 'All Users', id: 0 });
    const [users, setUsers] = useState([
        { name: 'All Users', id: 0 }
    ])


    const onChangeHandler = (f: number) => {
        props.onChange(f);
    }

    const selectedUserTemplate = (option: any, props: any) => {
            return (
                <div className="flex items-center gap-x-2">
                    <User size={24}/>
                    <div>{option ? option.name : props.placeholder}</div>
                </div>
            );
    };

    useEffect(() => {
        const matchedUser = users.find(u => u.id === props.user);
        if (matchedUser) {
            setSelectedUser(matchedUser);
        } else {
            console.warn(`No matching user found for user id: ${props.user}`);
        }
    }, [props.user, users]);

    useEffect(() => {
        if (userAttributes?.workspaceUsers) {
            const newUsers = userAttributes.workspaceUsers.map((u: WorkspaceUser) => ({
                name: `${u.firstName} ${u.lastName}`,
                id: u.id,
            }));
            setUsers((prevUsers) => [
                ...prevUsers,
                ...newUsers.filter((newUser) => !prevUsers.some((user) => user.id === newUser.id)),
            ]);
        }
    }, [userAttributes]);

    return (
        <div className="flex flex-row items-center gap-x-2  w-full sm:w-auto">
            <Dropdown value={selectedUser}
                      onChange={(e) => onChangeHandler(e.value.id)}
                      valueTemplate={selectedUserTemplate}
                      options={users}
                      optionLabel="name"
                      placeholder="Select a User"
                      pt={{
                          root: {
                              className:
                                  'hover:border-secondary focus:border-secondary focus:ring-2 focus:ring-secondary/40 shadow-none w-full sm:w-auto',
                          },
                          input: {
                              className:
                                  'py-1.5 px-2 text-sm text-secondary',
                          },
                          trigger: {
                              className:
                                  'text-secondary-light w-auto py-1.5 px-2',
                          },
                          item: {
                              className:
                                  'text-secondary aria-selected:bg-primary-light p-2',
                          },
                          list: {
                              className: 'py-2',
                          },
                      }}/>
        </div>
    );
}

export default UserPicker;