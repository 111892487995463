import {AsteriskSimple, CircleNotch, Play, Prohibit} from '@phosphor-icons/react';
import {AddressElement, PaymentElement, useElements, useStripe,} from '@stripe/react-stripe-js';
import {FormEvent, useContext, useState} from 'react';

import {getAxios, getErrorMessage} from '../../core/utils';
import {BeBadge, BeButton} from '../../core/beUi';
import {useToast} from '../../core/hooks';
import {ToastContext, UserContext} from '../../core/contexts';
import BillingLoading from "./billingLoading";
import {DateTime} from "luxon";
import {SubscriptionStatus} from "../../core/contexts/User/types";
import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog';
import {putApiBillingCancelScheduledCancellation, putApiBillingCancelSubscription} from "../../clients/api";


const BillingForm = () => {
    const { userAttributes, subscriptionDetails } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [cancelling, setCancelling] = useState(false);
    const [isStripeLoading, setIsStripeLoading] = useState(true);
    const stripe = useStripe();
    const elements = useElements();
    const toastRef = useContext(ToastContext);
    const { showToast } = useToast(toastRef);

    const handlePayment = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!stripe || !elements) return;
        try {
            setLoading(true);
            const { error } = await stripe.confirmSetup({
                elements,
                confirmParams: {
                    return_url: `${process.env.REACT_APP_BASE_URL}/billing/verify`,
                },
            });

            if (error) {
                showToast({
                    severity: 'error',
                    summary: 'failed',
                    detail: error.message,
                });
            }
        } catch (error) {
            showToast({
                severity: 'error',
                summary: 'failed',
                detail: getErrorMessage(error),
            });
        }
        setLoading(false);
    };

   const handleCancellation = async () => {
        try {
            setCancelling(true);
            const axios = await getAxios();
            await putApiBillingCancelSubscription({ client: axios});
            showToast({
                severity: 'success',
                summary: 'success',
                detail: 'Cancelled successfully!',
            });
           // location.reload();
        } catch (error) {
            showToast({
                severity: 'error',
                summary: 'failed',
                detail: getErrorMessage(error),
            });
            setCancelling(false);
        }
        location.reload();
    }

    const handleScheduledCancellation = async () => {
        try {
            setCancelling(true);
            const axios = await getAxios();
            await putApiBillingCancelScheduledCancellation({ client: axios});
            showToast({
                severity: 'success',
                summary: 'success',
                detail: 'Cancelled scheduled cancellation successfully!',
            });
            // location.reload();
        } catch (error) {
            showToast({
                severity: 'error',
                summary: 'failed',
                detail: getErrorMessage(error),
            });
            setCancelling(false);
        }
        location.reload();
    }

    const handleCancelSubscription = async (e:any) => {
        e.preventDefault();
        const endOfSubscription = subscriptionDetails ? `on the ${DateTime.fromISO(subscriptionDetails.nextPaymentDueDate).toLocaleString(DateTime.DATE_MED)}` : 'Now';
        confirmDialog({
            message: <div className="flex flex-col">
                <div className="-ml-4">If you proceed, your subscription will end {endOfSubscription}.</div>
                <div className="-ml-4">Are you sure you want to cancel your subscription?</div>
            </div>,
            header: 'Confirmation',
            defaultFocus: 'none',
            acceptLabel: 'Yes, proceed',
            acceptClassName: 'text-white bg-danger border-none focus:ring-transparent',
            rejectClassName: 'text-red-600 bg-white border-none focus:ring-transparent',
            accept: async () => handleCancellation()
        });
    }

    const handleCancelScheduledCancellation = async (e:any) => {
        e.preventDefault();
        const endOfSubscription = subscriptionDetails ? `on the ${DateTime.fromISO(subscriptionDetails.nextPaymentDueDate).toLocaleString(DateTime.DATE_MED)}` : 'Now';
        confirmDialog({
            message: <div className="flex flex-col">
                <div className="-ml-4">If you proceed, you will be billed {endOfSubscription}.</div>
                <div className="-ml-4">Are you sure you want to cancel the scheduled cancellation?</div>
            </div>,
            header: 'Confirmation',
            defaultFocus: 'none',
            acceptLabel: 'Yes, proceed',
            acceptClassName: 'text-white bg-danger border-none focus:ring-transparent',
            rejectClassName: 'text-red-600 bg-white border-none focus:ring-transparent',
            accept: async () => handleScheduledCancellation()
        });
    }


    const getSubscriptionStatus = (status: SubscriptionStatus, trialEnd: string, cancelAtPeriodEnd: boolean, hasPaymentMethod: boolean) => {
       if(cancelAtPeriodEnd && status !== SubscriptionStatus.CANCELED) {
           return <BeBadge severity={"warning"} variant={"outlined"}>Cancellation scheduled for end of billing period</BeBadge>;
       } else if(!hasPaymentMethod) {
           return <BeBadge severity={"warning"} variant={"outlined"}>Payment details required</BeBadge>;
       } else {
           switch (status) {
               case SubscriptionStatus.ACTIVE:
                   return <BeBadge severity={"success"} variant={"outlined"}>Active</BeBadge>;
               case SubscriptionStatus.CANCELED:
                   return <BeBadge severity={"danger"} variant={"outlined"}>Cancelled</BeBadge>;
               case SubscriptionStatus.INCOMPLETE:
                   return <BeBadge severity={"info"} variant={"outlined"}>Incomplete</BeBadge>;
               case SubscriptionStatus.INCOMPLETE_EXPIRED:
                   return <BeBadge severity={"danger"} variant={"outlined"}>Expired</BeBadge>;
               case SubscriptionStatus.TRIALING:
                   return <BeBadge severity={"info"}
                                   variant={"outlined"}>{`Trial ends ${DateTime.fromISO(trialEnd).toLocaleString({
                       month: 'short',
                       day: 'numeric'
                   })}`}</BeBadge>;
               case SubscriptionStatus.PAST_DUE:
                   return <BeBadge severity={"warning"} variant={"outlined"}>Payment due</BeBadge>;
               case SubscriptionStatus.UNPAID:
                   return <BeBadge severity={"warning"} variant={"outlined"}>Payment failed</BeBadge>;
           }
       }
    }

    return (
        <>
            <ConfirmDialog />
            {isStripeLoading && <BillingLoading />}
            <div className={`${isStripeLoading ? 'hidden' : 'flex'} flex-col gap-y-6`}>

                    <div className="flex flex-col justify-between align-middle lg:flex-row">
                        <h2 className="mb-4 text-4xl font-bold text-black">
                            Subscription Details
                        </h2>
                        { !subscriptionDetails?.cancelAtPeriodEnd && <div><BeButton
                            size="sm"
                            icon={cancelling ? <CircleNotch size="1.75rem" className="animate-spin"/> : <Prohibit size="1.5rem"/>}
                            variant="danger"
                            disabled={cancelling}
                            onClick={handleCancelSubscription}
                        >Cancel subscription</BeButton></div> }

                        { subscriptionDetails?.cancelAtPeriodEnd && <div><BeButton
                            size="sm"
                            icon={cancelling ? <CircleNotch size="1.75rem" className="animate-spin"/> : <Play size="1.5rem"/>}
                            variant="danger"
                            disabled={cancelling}
                            onClick={handleCancelScheduledCancellation}
                        >Resume subscription</BeButton></div> }
                    </div>


                    <div className="flex flex-col-reverse gap-7 lg:flex-row lg:items-start">
                        <div className="flex grow flex-col gap-y-6">
                            <div className="flex grow gap-x-16 rounded-md border border-[#e6e6e6] bg-white p-6 shadow-sm">
                                {subscriptionDetails && <div className="flex flex-col gap-y-2 w-full">
                                    <div className="flex w-full flex-row">
                                        <span className="min-w-48 flex-1">Users</span>
                                        <div className="flex-1 font-semibold">{subscriptionDetails.seatQty}</div>
                                    </div>
                                    <div className="flex w-full flex-row">
                                        <span className="min-w-48 flex-1">WorkingBe</span>
                                        <div className="flex-1 font-semibold">${(subscriptionDetails.pricePerSeat / 100).toFixed(2)} <span className="ml-2 font-light">AUD a user per month</span></div>
                                    </div>
                                    <div className="flex w-full flex-row">
                                        <span className="min-w-48 flex-1">Next Payment Date</span>
                                        <div className="flex-1 font-semibold">{[SubscriptionStatus.PAST_DUE, SubscriptionStatus.UNPAID,].includes(subscriptionDetails.status) ? "Now" : `${DateTime.fromISO(subscriptionDetails.nextPaymentDueDate).toLocaleString(DateTime.DATE_MED)}`}</div>
                                    </div>
                                    <div className="flex flex-row">
                                        <span className="min-w-48 flex-1">Total Subscription Amount</span>
                                        <div className="flex-1 font-semibold">${(subscriptionDetails.totalSubscriptionAmount / 100).toFixed(2)} <span className="ml-2 font-light">AUD including GST</span></div>
                                    </div>
                                    <div className="flex flex-row">
                                        <span className="min-w-48 flex-1">Subscription Status</span>
                                        <div className="flex flex-1 align-middle font-semibold">{getSubscriptionStatus(subscriptionDetails.status, subscriptionDetails.trialEnd, subscriptionDetails.cancelAtPeriodEnd, subscriptionDetails.hasPaymentMethod)}</div>
                                    </div>

                                </div>}
                            </div>
                            <form onSubmit={handlePayment}>
                                <PaymentElement
                                    onReady={() => setIsStripeLoading(false)}
                                    options={{
                                        layout: 'accordion',
                                        defaultValues: {
                                            billingDetails: {
                                                name: `${userAttributes?.given_name ?? ''} ${userAttributes?.family_name ?? ''}`,
                                                email: userAttributes?.email,
                                                phone: userAttributes?.phone_number,
                                            },
                                        },
                                    }}
                                    className="mb-6"
                                />
                                <h3 className="my-4 text-3xl font-bold text-black">
                                    Billing Address
                                </h3>
                                <AddressElement
                                    // onChange={(e) => console.log(e)}
                                    options={{
                                        mode: 'billing',
                                        display: {
                                            name: 'split',
                                        },
                                        fields: {
                                            phone: 'always',
                                        },
                                        validation: {
                                            phone: {
                                                required: 'always',
                                            },
                                        },
                                        defaultValues: {
                                            firstName: userAttributes?.given_name,
                                            lastName: userAttributes?.family_name,
                                            address: {
                                                country:
                                                    userAttributes?.[
                                                        'custom:country'
                                                        ] || 'AU',
                                            },
                                            phone: userAttributes?.phone_number,
                                        },
                                    }}
                                />
                                <div className="flex justify-between gap-x-4 align-middle">
                                    <BeButton
                                        type="submit"
                                        size="lg"
                                        fullWidth
                                        disabled={loading}
                                        className="mt-3"
                                    >
                                        {loading ? (
                                            <CircleNotch
                                                size="1.75rem"
                                                className="animate-spin"
                                            />
                                        ) : (
                                            'Update payment details'
                                        )}
                                    </BeButton>
                                </div>
                            </form>
                        </div>
                        <div
                            className="flex-col items-start gap-6 rounded-md border border-[#e6e6e6] bg-white p-6 shadow-sm">
                            <div className="flex flex-col gap-4">
                                <h3 className="text-2xl font-bold">
                                    Subscription FAQ
                                </h3>
                                <ul className="flex flex-col gap-y-2">
                                    <li className="flex items-center gap-x-1.5 leading-5">
                                        <AsteriskSimple
                                            size="1.5rem"
                                            weight="bold"
                                            className="shrink-0"
                                        />
                                        Each user is $19.90 per month
                                    </li>
                                    <li className="flex items-center gap-x-1.5 leading-5">
                                        <AsteriskSimple
                                            size="1.5rem"
                                            weight="bold"
                                            className="shrink-0"
                                        />
                                        Add and remove users any time, no lock-in
                                        contracts
                                    </li>
                                    <li className="flex items-center gap-x-1.5 leading-5">
                                    <AsteriskSimple
                                            size="1.5rem"
                                            weight="bold"
                                            className="shrink-0"
                                        />
                                        New users will be billed on a pro-rata basis
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

            </div>
            </>
            );
            };

            export default BillingForm;
