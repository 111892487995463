import {BeButton} from "../../../core/beUi";
import {AsteriskSimple, CircleNotch} from "@phosphor-icons/react";
import {useContext, useState} from "react";
import {getAxios, getErrorMessage} from "../../../core/utils";
import {ToastContext} from "../../../core/contexts";
import {useAuth, useToast} from "../../../core/hooks";
import React from "react";
import {putApiBillingRenewSubscription} from "../../../clients/api";


const billingCancelledSubscriptionPage = () => {

    const {userAttributes, subscriptionDetails} = useAuth();
    const [loading, setLoading] = useState(false);
    const toastRef = useContext(ToastContext);
    const { showToast } = useToast(toastRef);

    const handleRenewSubscription = async () => {
        if(userAttributes) {
            try {
                setLoading(true);
                const axios = await getAxios();
                await putApiBillingRenewSubscription({
                    client: axios,
                    body: {
                        workspaceId: userAttributes.workspace.workspaceId
                    }
                });
                showToast({
                    severity: 'success',
                    summary: 'success',
                    detail: 'Renewed successfully!',
                });
                location.reload();
            } catch (error) {
                showToast({
                    severity: 'error',
                    summary: 'failed',
                    detail: getErrorMessage(error),
                });
                setLoading(false);
            }
        }
    }

    return <div className={`flex flex-col gap-y-6`}>
        <h2 className="mb-4 text-4xl font-bold text-black">
            Subscription Details
        </h2>
        <div className="flex flex-col-reverse gap-7 lg:flex-row lg:items-start">
            <div className="flex grow flex-col gap-y-6">
                <div className="flex grow flex-col gap-x-16 rounded-md border border-[#e6e6e6] bg-white p-6 shadow-sm">
                    <h4 className="mb-4 text-2xl font-bold text-black">Your subscription has been cancelled</h4>
                    { subscriptionDetails?.isSubscriptionOwner && <React.Fragment>
                        <p>If you think this is an error, please email <a href="mailto:support@workingbe.com" className="font-medium underline">support@workingbe.com</a>.</p>
                        <p>Alternatively renew your subscription below.</p>
                        <div className="mt-2 flex justify-start">
                            <BeButton
                                type="submit"
                                size="lg"
                                icon={loading ?  <CircleNotch
                                    size="1.75rem"
                                    className="animate-spin"
                                /> : <></>}
                                disabled={loading}
                                className="mt-3"
                                onClick={() => handleRenewSubscription()}
                            >
                               Renew subscription
                            </BeButton>
                        </div>
                    </React.Fragment> }
                    { !subscriptionDetails?.isSubscriptionOwner && <React.Fragment>
                        <p>To continue using the service, please contact the account owner to update the subscription.</p>
                    </React.Fragment>}
                </div>
            </div>
            <div className="flex-col items-start gap-6 rounded-md border border-[#e6e6e6] bg-white p-6 shadow-sm">
                <div className="flex flex-col gap-4">
                    <h3 className="text-2xl font-bold">
                        Subscription FAQ
                    </h3>
                    <ul className="flex flex-col gap-y-2">
                        <li className="flex items-center gap-x-1.5 leading-5">
                            <AsteriskSimple
                                size="1.5rem"
                                weight="bold"
                                className="shrink-0"
                            />
                            Each user is $19.90 per month
                        </li>
                        <li className="flex items-center gap-x-1.5 leading-5">
                            <AsteriskSimple
                                size="1.5rem"
                                weight="bold"
                                className="shrink-0"
                            />
                            Add and remove users any time, no lock-in
                            contracts
                        </li>
                        <li className="flex items-center gap-x-1.5 leading-5">
                            <AsteriskSimple
                                size="1.5rem"
                                weight="bold"
                                className="shrink-0"
                            />
                            New users will be billed on a pro-rata basis
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
}

export default billingCancelledSubscriptionPage;