import {
    fetchUserAttributes,
    getCurrentUser as getCurrentAuthenticatedUser,
} from '@aws-amplify/auth';
import {SubscriptionDetails, UserAttributes, Workspace} from './types';
import {UserRole} from "../../enums/userRole";
import {DateTime} from "luxon";
import getPipelineAxios from "../../utils/getPipelineAxios";
import {
    getSubscriptionStatusProdApiBillingGetSubscriptionStatusGet,
    getUserDetailsProdApiUserGetLoggedInUserGet
} from "../../../clients/pipeline";
import {getAxios} from "../../utils";
import {getApiBillingGetSubscriptionStatus} from "../../../clients/api";

export const getCurrentUser = async () => {
    try {
        return await getCurrentAuthenticatedUser();
    } catch {
        return undefined;
    }
};

export const getCurrentUserAttributes = async (): Promise<
    UserAttributes | undefined
> => {
    try {
        const axios = await getPipelineAxios();
        const amplifyUserAttributes = await fetchUserAttributes();
        const { data: userAttributes }: any = await getUserDetailsProdApiUserGetLoggedInUserGet({ client: axios });
        return {
            ...amplifyUserAttributes,
            ...(userAttributes.successful && {
                id: userAttributes.results.id,
                stripeId: userAttributes.results.stripeDetails?.stripeId,
                defaultAstuteId: userAttributes.results.astuteId,
                workspace: {
                    workspaceId: userAttributes.results.ownedWorkspaces.length ? userAttributes.results.ownedWorkspaces[0].id : null,
                    workspaceName: userAttributes.results.ownedWorkspaces.length ? userAttributes.results.ownedWorkspaces[0].name : '',
                    role: userAttributes.results.workspaceUserRoles.length ? userAttributes.results.workspaceUserRoles[0].role : null,
                },
                workspaceUsers: userAttributes.results.workspaceUsers,
            })
        };
    } catch {
        return undefined;
    }
};


export const getSubscriptionStatus = async (workspaceId: number|undefined):  Promise<SubscriptionDetails|null|undefined> => {
    if (workspaceId) {
        try {
            //const axios = await getPipelineAxios();
            const axios = await getAxios();
            return getApiBillingGetSubscriptionStatus({
            //return getSubscriptionStatusProdApiBillingGetSubscriptionStatusGet({
                client: axios,
                query: {
                    workspaceId
                }
            })
                .then((r: any) => {
                    const subscription: SubscriptionDetails | null = (r.data && r.data.results) ? r.data.results : null;
                    if (subscription) {
                        subscription.trialDaysRemaining = (subscription.trialEnd) ? Math.ceil(DateTime.fromISO(subscription.trialEnd)
                            .diff(DateTime.now(), 'days')
                            .as('days')) : 0;
                    }
                    return subscription;
                }).catch(() => undefined);
        } catch {
            return undefined;
        }
    }
    return undefined;
}
