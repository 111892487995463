import {CalendarBlank} from "@phosphor-icons/react";
import {Dropdown} from "primereact/dropdown";
import {useEffect, useState} from "react";
import {FrequencyEnum} from "../../enums/frequencyEnum";
import {FrequencyPickerProps} from "./frequencyPickerProps";

const FrequencyPicker = (props: FrequencyPickerProps) => {
    const [frequency, setFrequency] = useState( { name: 'Quarterly', code: FrequencyEnum.Quarterly});

    const frequencies = [
        { name: 'Monthly', code: FrequencyEnum.Monthly },
        { name: 'Quarterly', code: FrequencyEnum.Quarterly },
        { name: 'Yearly', code: FrequencyEnum.Yearly },
    ];

    const onChangeHandler = (f: FrequencyEnum) => {
        props.onChange(f);
    }

    useEffect(() => {
        if (props.frequency) {
            const matchedFrequency = frequencies.find(freq => freq.code === props.frequency);
            if (matchedFrequency) {
                setFrequency(matchedFrequency);
            } else {
                console.warn(`No matching frequency found for code: ${props.frequency}`);
            }
        }
    }, [props.frequency]);

    const selectedFrequencyTemplate = (option: any, props: any) => {
        return (
            <div className="flex items-center gap-x-2">
                <CalendarBlank size={24}/>
                <div>{option ? option.name : props.placeholder}</div>
            </div>
        );
    };

            return (
        <div className="flex flex-row items-center gap-x-2  w-full sm:w-auto">
            <Dropdown value={frequency}
                      onChange={(e) => onChangeHandler(e.value.code)}
                      valueTemplate={selectedFrequencyTemplate}
                      options={frequencies}
                      optionLabel="name"
                      placeholder="Select a Frequency"
                      pt={{
                          root: {
                              className:
                                  'hover:border-secondary focus:border-secondary focus:ring-2 focus:ring-secondary/40 shadow-none  w-full sm:w-auto',
                          },
                          input: {
                              className:
                                  'py-1.5 px-2 text-sm text-secondary',
                          },
                          trigger: {
                              className:
                                  'text-secondary-light w-auto py-1.5 px-2',
                          },
                          item: {
                              className:
                                  'text-secondary aria-selected:bg-primary-light p-2',
                          },
                          list: {
                              className: 'py-2',
                          },
                      }}/>
        </div>
    );
}

export default FrequencyPicker;