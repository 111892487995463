
import {Dropdown} from "primereact/dropdown";
import {useAuth} from "../../../../core/hooks";
import {useEffect, useState} from "react";
import {WorkspaceUser} from "../../../../core/contexts/User/types";
import {RolePickerProps} from "./rolePickerProps";
import {IdentificationBadge} from "@phosphor-icons/react";

const RolePicker = (props: RolePickerProps) => {

    const { userAttributes } = useAuth();
    const [selectedRole, setSelectedRole] = useState({ name: 'All Roles', id: '' });
    const [roles, setRoles] = useState([
        { name: 'All Roles', id: '' }
    ])


    const onChangeHandler = (f: string) => {
        props.onChange(f);
    }

    const selectedRoleTemplate = (option: any, props: any) => {
        return (
            <div className="flex items-center gap-x-2">
                <IdentificationBadge size={24}/>
                <div>{option ? option.name : props.placeholder}</div>
            </div>
        );
    };

    useEffect(() => {
        const matchedRole = roles.find(u => u.id === props.role);
        if (matchedRole) {
            setSelectedRole(matchedRole);
        } else {
            console.warn(`No matching user found for user id: ${props.role}`);
        }
    }, [props.role]);

    useEffect(() => {
        if (userAttributes?.workspaceUsers) {
            const newRoles = userAttributes.workspaceUsers.map((u: WorkspaceUser) => ({
                name: `${u.role}`,
                id: u.role,
            }));

            setRoles((prevRoles) => {
                const allRoles = [...prevRoles, ...newRoles];
                const uniqueRoles = Array.from(
                    new Map(allRoles.map((role) => [role.id, role])).values()
                );
                return uniqueRoles;
            });
        }
    }, [userAttributes]);

    return (
        <div className="flex flex-row items-center gap-x-2 w-full sm:w-auto">
            <Dropdown value={selectedRole}
                      onChange={(e) => onChangeHandler(e.value.id)}
                      valueTemplate={selectedRoleTemplate}
                      options={roles}
                      optionLabel="name"
                      placeholder="Select a Role"
                      pt={{
                          root: {
                              className:
                                  'hover:border-secondary focus:border-secondary focus:ring-2 focus:ring-secondary/40 shadow-none w-full sm:w-auto',
                          },
                          input: {
                              className:
                                  'py-1.5 px-2 text-sm text-secondary',
                          },
                          trigger: {
                              className:
                                  'text-secondary-light w-auto py-1.5 px-2',
                          },
                          item: {
                              className:
                                  'text-secondary aria-selected:bg-primary-light p-2',
                          },
                          list: {
                              className: 'py-2',
                          },
                      }}/>
        </div>
    );
}

export default RolePicker;