import {useContext} from 'react';
import {Routes} from "./routes/routes";
import {UserContext} from "./core/contexts";
import SplashLoader from "./layouts/common/splashLoader/fullscreenLoader";

function App() {
  const { currentUser, userAttributes, isLoading, hasActiveSubscription, subscriptionDetails } = useContext(UserContext);
  return (isLoading || currentUser === null || userAttributes === null || hasActiveSubscription === undefined) ? (<SplashLoader />) :  ( <Routes isAuthorized={!!currentUser} subscriptionDetails={subscriptionDetails} hasActiveSubscription={hasActiveSubscription} role={userAttributes?.workspace?.role} />);

}

export default App;
