import {AuthUser} from '@aws-amplify/auth';
import {Hub} from 'aws-amplify/utils';
import {ReactNode, useCallback, useEffect, useState} from 'react';

import UserContext from './UserContext';
import {getCurrentUser, getCurrentUserAttributes, getSubscriptionStatus} from './utils';
import {SubscriptionDetails, SubscriptionStatus, UserAttributes} from './types';

const UserContextProvider = ({
    children,
}: Readonly<{
    children: ReactNode;
}>) => {
    const [currentUser, setCurrentUser] = useState<AuthUser | null | undefined>(null);
    const [userAttributes, setUserAttributes] = useState<UserAttributes | null | undefined>(null);
    const [subscriptionDetails, setSubscriptionDetails] = useState<SubscriptionDetails | null | undefined>(null);
    const [hasActiveSubscription, setHasActiveSubscription] = useState<boolean | null>();
    const [isLoading, setIsLoading] = useState(true);

    const refreshUserAttributes = useCallback(
        async () => setUserAttributes(await getCurrentUserAttributes()),
        []
    );


    useEffect(() => {
        setHasActiveSubscription(subscriptionDetails ? [SubscriptionStatus.ACTIVE,SubscriptionStatus.TRIALING].includes(subscriptionDetails.status) && subscriptionDetails.hasPaymentMethod : null);
    }, [subscriptionDetails]);


    useEffect(() => {
        const updateUser = async () => {
            setIsLoading(true);
            const d = await getCurrentUser();
            const ua = await getCurrentUserAttributes();
            const sd = await getSubscriptionStatus(ua?.workspace.workspaceId);
            setSubscriptionDetails(sd);
            setUserAttributes(ua);
            setCurrentUser(d);
            setIsLoading(false);
        };
        const hubListenerRemove = Hub.listen('auth', updateUser);
        updateUser();
        return () => hubListenerRemove();
    }, []);

    return (
        <UserContext.Provider
            value={{
                currentUser,
                userAttributes,
                isLoading,
                refreshUserAttributes,
                subscriptionDetails,
                hasActiveSubscription
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserContextProvider;
