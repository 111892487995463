import {Outlet, useNavigate} from "react-router-dom";
import {BeButton, BeToast} from "../core/beUi";
import React, {useContext, useEffect, useState} from "react";
import { ToastContext, UserContext} from "../core/contexts";
import {useWindowSize} from "usehooks-ts";
import {List, SignOut, UserCircle} from "@phosphor-icons/react";
import {signOut} from "aws-amplify/auth";
import Logo from "./common/logo/logo";
import Sidebar from "./sidebar/sidebar";
import {getRoleString} from "../core/utils/getRoleString";
import {AppDispatch} from "../core/state/store";
import {useDispatch} from "react-redux";
import {APP_OPEN_MOBILE_SIDEBAR} from "../core/state/app/appActions";
import {SubscriptionStatus} from "../core/contexts/User/types";
import {useHealthCheck} from "../core/hooks/useHeathCheck";

const MainLayout = () => {

    useHealthCheck();

    const toastRef = useContext(ToastContext);
    const { width } = useWindowSize();
    const navigate = useNavigate();
    const { userAttributes, subscriptionDetails } = useContext(UserContext);
    const [role, setRole] = useState('');
    const dispatch: AppDispatch = useDispatch();

    const handleSignOut = async () => {
        await signOut();
        navigate('/');
    };

    useEffect(() => {
        if(userAttributes && userAttributes.workspace && userAttributes.workspace.role) {
            setRole(getRoleString(userAttributes.workspace.role));
        }
    }, [userAttributes]);

    return (
        <>
            <BeToast ref={toastRef} />
            <div className="flex h-screen w-screen flex-col items-stretch justify-start overflow-hidden">
                <header className="flex h-20 items-center justify-between gap-x-6 bg-primary px-5 py-4">
                    <div className="flex items-center justify-start gap-x-2">
                        <div className="max-h-10 w-10 lg:w-56">
                            <a href="#" onClick={(event) => {
                                event.preventDefault();
                                navigate('/dashboard');
                            }}><Logo iconOnly={width < 1024}/></a>
                        </div>
                    </div>
                    <div className="flex items-center justify-start gap-x-2">
                        { subscriptionDetails?.status === SubscriptionStatus.TRIALING && <BeButton
                            variant="outlined"
                            className="border-secondary bg-secondary text-sm text-white"
                            onClick={() => navigate('/billing')}
                        >
                            { subscriptionDetails.trialDaysRemaining } days remaining
                        </BeButton> }

                        <BeButton
                            size="sm"
                            variant="ghost"
                            className="hover:bg-primary-light hover:text-secondary"
                            menu={[
                                {
                                    template: <div className="mb-2 border-b px-4 py-3">
                                        <div className="flex items-center gap-x-1">
                                            <span className="block text-gray-900">{`${userAttributes?.given_name} ${userAttributes?.family_name}`}</span>
                                            <span className="block font-light text-gray-900 sm:hidden">({role})</span>
                                        </div>
                                        <div className="block truncate text-sm font-light text-gray-500">{`${userAttributes?.email}`}</div>
                                    </div>
                                },
                                {
                                    label: 'Logout',
                                    icon: <SignOut size="1.75rem"/>,
                                    command: () => handleSignOut(),
                                },
                            ]}
                        >
                            <div className="flex items-center gap-x-2">
                                <UserCircle size="2rem" weight="fill"/>
                                <div className="hidden sm:block">{`${userAttributes?.given_name} ${userAttributes?.family_name}`} <span className="font-light text-gray-900">({role})</span> </div>
                            </div>
                        </BeButton>
                        <BeButton
                            size="sm"
                            variant="ghost"
                            className="hover:bg-primary-light hover:text-secondary sm:hidden"
                            onClick={() => dispatch({ type: APP_OPEN_MOBILE_SIDEBAR})}>
                            <List size="2rem"/>
                        </BeButton>
                    </div>
                </header>
                <div className="flex min-h-0 flex-1">
                    <Sidebar />
                    <div className="h-full grow overflow-y-auto bg-brandGray-6 p-6 scrollbar-thin">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    )
}

export default MainLayout;