import { CheckCircle } from '@phosphor-icons/react';
import { DragEventHandler, useContext, useState } from 'react';
import NewWindow from 'react-new-window';
import { BeButton } from '../../core/beUi';
import { ConnectedAppsContext } from '../../core/contexts';

import {Image} from "primereact/image";
import AstuteDialog from "./dialogs/astuteDialog";

export default function IntegrationsPage() {
    const [showAstuteModal, setShowAstuteModal] = useState(false);
    const [showJobAdderWindow, setShowJobAdderWindow] = useState(false);
    const [showXeroWindow, setShowXeroWindow] = useState(false);
    const { isJobAdderConnected, isAstuteConnected, isXeroConnected } =
        useContext(ConnectedAppsContext);

    const handleOnAstuteDialogClose = () => {
        setShowAstuteModal(false);
    };

    const preventImageDrag: DragEventHandler<HTMLImageElement> = (e) =>
        e.preventDefault();

    return (
        <div className="flex flex-col gap-y-8">
            <div>
                <h2 className="mb-4 text-4xl font-bold text-black">
                    Integrations
                </h2>
                <p className="text-base text-black">
                    To use all of the features of WorkingBe you’ll need to add
                    your software integrations.
                </p>{' '}
                <p>
                    If you need support, get in touch at{' '}
                    <a
                        href="mailto:help@workingbe.com"
                        target="_blank"
                        className="underline underline-offset-4"
                    >
                        help@workingbe.com
                    </a>
                </p>
            </div>
            <div className="flex flex-wrap gap-10">
                <div className="flex w-[32rem] flex-col items-start gap-y-2 rounded-xl bg-white px-6 py-4 shadow-sm">
                    <Image
                        alt="JobAdderCallback"
                        src="/job-adder.png"
                        width="200"
                        height="54"
                        onDragStart={preventImageDrag}
                    />
                    <span className="mb-2 text-base font-normal text-black">
                        Pull data from JobAdderCallback to empower your teams.
                    </span>
                    {isJobAdderConnected ? (
                        <CheckCircle
                            size="3rem"
                            className="mt-auto text-emerald-400"
                        />
                    ) : (
                        <BeButton
                            className="mt-auto"
                            onClick={() => setShowJobAdderWindow(true)}
                        >
                            Connect
                        </BeButton>
                    )}
                </div>
                <div className="flex w-[32rem] flex-col items-start gap-y-2 rounded-xl bg-white px-6 py-4 shadow-sm">
                    <Image
                        alt="AstutePayroll"
                        src="/astute-payroll.png"
                        width="200"
                        height="35"
                        onDragStart={preventImageDrag}
                    />
                    <span className="mb-2 text-base font-normal text-black">
                        Display invoices and payroll data right here in
                        WorkingBe.
                    </span>
                    {isAstuteConnected ? (
                        <CheckCircle
                            size="3rem"
                            className="mt-auto text-emerald-400"
                        />
                    ) : (
                        <BeButton
                            className="mt-auto"
                            onClick={() => setShowAstuteModal(true)}
                        >
                            Connect
                        </BeButton>
                    )}
                </div>
                <div className="flex w-[32rem] flex-col items-start gap-y-2 rounded-xl bg-white px-6 py-4 shadow-sm">
                    <Image
                        alt="Xero"
                        src="/xero.png"
                        width="100"
                        height="50"
                        onDragStart={preventImageDrag}
                    />
                    <span className="mb-2 text-base font-normal text-black">
                        Get payroll and invoice information from Xero.
                    </span>
                    {isXeroConnected ? (
                        <CheckCircle
                            size="3rem"
                            className="mt-auto text-emerald-400"
                        />
                    ) : (
                        <BeButton
                            className="mt-auto"
                            onClick={() => setShowXeroWindow(true)}
                        >
                            Connect
                        </BeButton>
                    )}
                </div>
            </div>
            {showXeroWindow && (
                <NewWindow
                    title="Connect Xero Account"
                    url={`https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${process.env.REACT_APP_XERO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_XERO_REDIRECT_URI}&scope=${process.env.REACT_APP_XERO_SCOPE}`}
                    center="screen"
                    features={{ height: 500, width: 500 }}
                />
            )}
            {showJobAdderWindow && (
                <NewWindow
                    title="Connect JobAdderCallback Account"
                    url={`https://id.jobadder.com/connect/authorize?response_type=code&client_id=${process.env.REACT_APP_JA_CLIENT_ID}&scope=${process.env.REACT_APP_JA_SCOPE}&redirect_uri=${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_JA_REDIRECT_URI}`}
                    center="screen"
                    features={{ height: 500, width: 500 }}
                />
            )}
            <AstuteDialog open={showAstuteModal} onClose={() => handleOnAstuteDialogClose()} />

        </div>
    );
}
