import { Skeleton } from 'primereact/skeleton';

const BillingLoading = () => (
    <div className="flex flex-col gap-y-6">
        <h2 className="mb-4 text-4xl font-bold text-black">
            Subscription Details
        </h2>
        <div className="flex flex-col-reverse gap-7 lg:flex-row lg:items-start">
            <div className="flex grow flex-col gap-y-6">
                <div className="flex gap-x-16 rounded-md border border-[#e6e6e6] bg-white p-6 shadow-sm">
                    <div className="flex flex-col gap-y-2">
                        <span>Users</span>
                        <span>WorkingBe</span>
                        <span>Next Payment Date</span>
                        <span>Total Subscription Amount</span>
                    </div>
                    <div className="flex grow flex-col gap-y-2 font-semibold">
                        <Skeleton height="1.5rem" />
                        <Skeleton height="1.5rem" />
                        <Skeleton height="1.5rem" />
                        <Skeleton height="1.5rem" />
                    </div>
                </div>
                <div className="flex flex-col gap-y-6">
                    <div className="flex gap-x-3">
                        <Skeleton height="3rem" className="flex-1 basis-1/2" />
                        <Skeleton height="3rem" className="flex-1 basis-1/4" />
                        <Skeleton height="3rem" className="flex-1 basis-1/4" />
                    </div>
                    {/*<div>*/}
                    {/*    <h2 className="mb-4 text-4xl font-bold text-black">*/}
                    {/*        Billing Address*/}
                    {/*    </h2>*/}
                    {/*    <div className="flex flex-col gap-y-3">*/}
                    {/*        <Skeleton height="3rem" />*/}
                    {/*        <Skeleton height="3rem" />*/}
                    {/*        <Skeleton height="3rem" />*/}
                    {/*        <Skeleton height="3rem" />*/}
                    {/*        <Skeleton height="3rem" />*/}
                    {/*        <Skeleton height="3rem" />*/}
                    {/*        <div className="flex gap-x-3">*/}
                    {/*            <Skeleton height="3rem" />*/}
                    {/*            <Skeleton height="3rem" />*/}
                    {/*        </div>*/}
                    {/*        <Skeleton height="3rem" />*/}
                    {/*        <Skeleton height="3rem" />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className="flex-col items-start gap-6 rounded-md border border-[#e6e6e6] bg-white p-6 shadow-sm">
                <div className="flex flex-col gap-4">
                    <h3 className="text-2xl font-bold">Subscription FAQ</h3>
                    <ul className="flex flex-col gap-y-2">
                        <Skeleton
                            height="1.25rem"
                            className="w-full min-w-96"
                        />
                        <Skeleton
                            height="1.25rem"
                            className="w-full min-w-96"
                        />
                        <Skeleton
                            height="1.25rem"
                            className="w-full min-w-96"
                        />
                    </ul>
                </div>
            </div>
        </div>
    </div>
);

export default BillingLoading;
