
import { useContext, useEffect, useState } from 'react';
import {UserContext} from "../contexts";


const useConnectedApps = () => {
    const { userAttributes, refreshUserAttributes } = useContext(UserContext);
    const [isJobAdderConnected, setIsJobAdderConnected] = useState<
        boolean | null
    >(null);
    const [isAstuteConnected, setIsAstuteConnected] = useState<boolean | null>(
        null
    );
    const [isXeroConnected, setIsXeroConnected] = useState<boolean | null>(
        null
    );
    const [isAllConnected, setIsAllConnected] = useState<boolean | null>(null);

    useEffect(() => {
        if (
            isJobAdderConnected === null ||
            isAstuteConnected === null ||
            isXeroConnected === null
        )
            return;
        setIsAllConnected(
            isJobAdderConnected && isAstuteConnected && isXeroConnected
        );
    }, [isJobAdderConnected, isAstuteConnected, isXeroConnected]);

    return {
        isAllConnected,
        isJobAdderConnected,
        setIsJobAdderConnected,
        isAstuteConnected,
        setIsAstuteConnected,
        isXeroConnected,
        setIsXeroConnected,
        refreshConnectedApps: refreshUserAttributes,
    };
};

export default useConnectedApps;
