import {combineReducers} from "redux";
import placementReducer from "./placements/placementReducer";
import appReducer from "./app/appReducer";
import dashboardReducer from "./dashboard/dashboardReducer";
import usersReducer from "../../pages/users/state/usersReducer";

const reducers = combineReducers({
    app: appReducer,
    users: usersReducer,
    placements: placementReducer,
    dashboard: dashboardReducer,
});

export default reducers;