import {AppDispatch} from "../store";
import getPipelineAxios from "../../utils/getPipelineAxios";
import {
    DASHBOARD_BILLING_LINE_GRAPH_LOAD, DASHBOARD_BILLING_LINE_GRAPH_LOADED,
    DASHBOARD_BILLING_SUMMARY_LOAD, DASHBOARD_BILLING_SUMMARY_LOADED,
    DASHBOARD_LATEST_PLACEMENTS_LOAD,
    DASHBOARD_LATEST_PLACEMENTS_LOADED
} from "./dashboardActions";
import {
    getBillingGraphBillingLineGraphGet, type GetBillingGraphBillingLineGraphGetResponse,
    getBillingSummaryBillingSummaryGet, type GetBillingSummaryBillingSummaryGetResponse,
    getPlacementsScoreboardPlacementsScoreboardGet,
    type GetPlacementsScoreboardPlacementsScoreboardGetResponse
} from "../../../clients/pipeline";
import {PlacementScoreboard} from "./interfaces/placementScoreboard";
import {FrequencyEnum} from "../../../pages/dashboard/enums/frequencyEnum";
import {BillingSummary} from "./interfaces/billingSummary";
import {LineGraphData} from "./interfaces/lineGraphData";


export function getBillingLineGraphData(filter: FrequencyEnum, userId: string = '') {
    return async (dispatch: AppDispatch) => {
        dispatch({type: DASHBOARD_BILLING_LINE_GRAPH_LOAD});

        const axios = await getPipelineAxios();
        return getBillingGraphBillingLineGraphGet({
            client: axios,
            query: {
                period: filter,
                ...(userId && { user_id: userId })
            }
        })
            .then((apiResponse: GetBillingGraphBillingLineGraphGetResponse|any) => {
                if (apiResponse.data?.status === 'success') {
                    const data: LineGraphData = apiResponse.data.result.billings;
                    dispatch({type: DASHBOARD_BILLING_LINE_GRAPH_LOADED, data});
                }
            })

    };
}


export function getBillingSummary(filter: FrequencyEnum, userId: string = '') {
    return async (dispatch: AppDispatch) => {
        dispatch({type: DASHBOARD_BILLING_SUMMARY_LOAD});

        const axios = await getPipelineAxios();
        return getBillingSummaryBillingSummaryGet({
            client: axios,
            query: {
                period: filter,
                ...(userId && { user_id: userId })
            }
        })
            .then((apiResponse: GetBillingSummaryBillingSummaryGetResponse|any) => {
                if (apiResponse.data?.status === 'success') {
                    const summary: BillingSummary = apiResponse.data.result;
                    dispatch({type: DASHBOARD_BILLING_SUMMARY_LOADED, summary});
                }
            })

    };
}


export function getLatestPlacements(pageSize = 10, sortField = '', sortOrder: 'asc'|'desc' = 'asc', userId: string = '') {
    return async (dispatch: AppDispatch) => {
        dispatch({type: DASHBOARD_LATEST_PLACEMENTS_LOAD});

        const axios = await getPipelineAxios();
        return getPlacementsScoreboardPlacementsScoreboardGet({
                client: axios,
                query: {
                    count: pageSize,
                    sort_by: sortField,
                    order: sortOrder,
                    ...(userId && { user_id: userId })
                }
            })
            .then((apiResponse: GetPlacementsScoreboardPlacementsScoreboardGetResponse|any) => {
                if (apiResponse.data?.status === 'success') {
                    const placements: PlacementScoreboard[] = Object.values(apiResponse.data.result);
                    dispatch({type: DASHBOARD_LATEST_PLACEMENTS_LOADED, placements});
                }
            })

    };
}