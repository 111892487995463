
import {Client, createClient} from '@hey-api/client-axios';
import {fetchAuthSession} from "@aws-amplify/auth";

let client: Client | null = null;
let tokenId: string | undefined = '';

const getPipelineAxios = async (token: string = ''): Promise<Client> => {
    if (!client || tokenId !== token) {
        const session = await fetchAuthSession();
        const idToken = session?.tokens?.idToken?.toString();
        tokenId = token || idToken;

        client = createClient({
            baseURL: process.env.REACT_APP_PIPELINE_API,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${tokenId}`,
                'X-API-KEY': process.env.REACT_APP_PIPELINE_APIKEY,
            },
        });
    }

    return client;
};

export default getPipelineAxios;
