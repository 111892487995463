import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import getPipelineAxios from "../utils/getPipelineAxios";
import {healthHealthGet} from "../../clients/pipeline";

export const useHealthCheck = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkHealth = async () => {
            const axios = await getPipelineAxios();
            const isHealthyResponse: any = await healthHealthGet({ client: axios });

            if (isHealthyResponse.data.message !== "Healthy") {
                navigate('/service-unavailable');
            }
        }
        checkHealth();
    }, [navigate]);
};