
import {useSearchParams} from "react-router-dom";
import {BeButton} from "../../../core/beUi";
import {useEffect, useState} from "react";
import {getAxios} from "../../../core/utils";
import {useAuth} from "../../../core/hooks";
import {TokenType} from "../../../core/enums/tokenType";
import {ProgressSpinner} from "primereact/progressspinner";
import {postApiTokenExchangeJobAdderAuthToken} from "../../../clients/api";


export default function JobAdderCallback() {
    const [searchParams] = useSearchParams();
    const {userAttributes} = useAuth();
    const code = searchParams.get("code");
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {

            const fetchAuthToken = async () => {
                const axios = await getAxios();
                await postApiTokenExchangeJobAdderAuthToken({
                    client: axios,
                    body: {
                        tokenSource: TokenType.JobAdder,
                        authCode: code,
                        workspaceId: userAttributes?.workspace?.workspaceId,
                        userId: userAttributes?.id,
                        localUri: process.env.NODE_ENV !== 'production'
                    }
                });
            }


                fetchAuthToken()
                    .then(_ => {
                        console.log(_);
                        window.close()
                    })
                    .catch(err => {
                        setIsLoading(false);
                        setError(err instanceof Error
                            ? err.message
                            : 'Something went wrong. Please, try again later.')
                    });


    }, [code]);

    return (
        <div className="flex flex-1 flex-col items-stretch bg-primary">
            <div className="flex flex-1 flex-col items-center gap-y-32 p-10 sm:gap-y-48 sm:p-16">
                { isLoading && <ProgressSpinner /> }
                { !isLoading &&  <div className="flex max-w-80 flex-col space-y-4">
                    <h1 className="text-3xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">{ error ? `Error` : `Congratulations!` }</h1>
                    <h3 className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight">{ error ? error : `You have successfully connected your JobAdder account.`}</h3>
                    <div><BeButton
                        variant="outlined"
                        size="lg"
                        fullWidth
                        className="max-w-72 shrink border-secondary sm:basis-72"
                        onClick={() => window.close()}
                    >
                        Close
                    </BeButton></div>
                </div> }
            </div>
        </div>
    );
}