import {Avatar} from "primereact/avatar";
import {CardWidgetProps} from "../interfaces/CardWidgetProps";

const CardWidget = (props: CardWidgetProps) => {

    return (
        <div className="group w-full rounded-2xl bg-white p-6 shadow-sm hover:bg-primary">
            <div className="flex items-center justify-between gap-x-2">
                <div>
                    <div className="text-base font-normal text-gray-600 text-nowrap">{props.label}</div>
                    <div className="text-4xl font-medium text-secondary">{props.value}</div>
                </div>
                <div className="mt-1">
                    <Avatar
                        size="large"
                        shape="circle"
                        className="bg-primary group-hover:bg-primary-light"
                    >
                        <props.icon size="1.5rem"/>
                    </Avatar>
                </div>
            </div>
        </div>
    );
}

export default CardWidget;