
import {BeLink} from "../../core/beUi";


export default function ServiceUnavailable() {
    return (
        <div className="flex flex-1 flex-col items-stretch bg-primary">
            <div className="flex flex-1 flex-col items-center gap-y-32 p-10 sm:gap-y-48 sm:p-16">
                <div className="flex max-w-80 flex-col space-y-4">
                    <h1 className="text-3xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Oops!</h1>
                    <h3 className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight">Service unavailable</h3>
                    <p>Looks like the API is down and temporarily unavailable.</p>
                    <div><BeLink
                        to="/"
                        variant="outlined"
                        size="lg"
                        fullWidth
                        className="max-w-72 shrink border-secondary sm:basis-72"
                    >
                        Try again
                    </BeLink></div>
                </div>
            </div>
        </div>
    );
}