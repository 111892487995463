import { ReactNode, useContext, useEffect, useState } from 'react';



import ConnectedAppsContext from './ConnectedAppsContext';
import UserContext from "../User/UserContext";
import {IntegrationType} from "../../enums/integrationType";


const ConnectedAppsContextProvider = ({
    children,
}: Readonly<{
    children: ReactNode;
}>) => {
    const { userAttributes, refreshUserAttributes } = useContext(UserContext);
    const [isJobAdderConnected, setIsJobAdderConnected] = useState<
        boolean | null
    >(null);
    const [isAstuteConnected, setIsAstuteConnected] = useState<boolean | null>(
        null
    );
    const [isXeroConnected, setIsXeroConnected] = useState<boolean | null>(
        null
    );
    const [isAllConnected, setIsAllConnected] = useState<boolean | null>(true); // TODO: Will be reverted back to null

    useEffect(() => {
        const [jobAdderConnected, astuteConnected, xeroConnected] = (
            //userAttributes?.integrations || []
            []
        ).reduce(
            ([accJobAdder, accAstute, accXero], { type }) => [
                accJobAdder || type === IntegrationType.JobAdder,
                accAstute || type === IntegrationType.Astute,
                accXero || type === IntegrationType.Xero,
            ],
            [false, false, false]
        );
        setIsJobAdderConnected(jobAdderConnected);
        setIsAstuteConnected(astuteConnected);
        setIsXeroConnected(xeroConnected);
        //setIsAllConnected(isJobAdderConnected && isAstuteConnected && isXeroConnected);
    }, [userAttributes]);

    // useEffect(() => {
    //     if (
    //         isJobAdderConnected === null ||
    //         isAstuteConnected === null ||
    //         isXeroConnected === null
    //     )
    //         return;
    //     setIsAllConnected(
    //         isJobAdderConnected && isAstuteConnected && isXeroConnected
    //     );
    // }, [isJobAdderConnected, isAstuteConnected, isXeroConnected]);

    return (
        <ConnectedAppsContext.Provider
            value={{
                isAllConnected,
                isJobAdderConnected,
                isAstuteConnected,
                isXeroConnected,
                refreshConnectedApps: refreshUserAttributes,
            }}
        >
            {children}
        </ConnectedAppsContext.Provider>
    );
};

export default ConnectedAppsContextProvider;
