import {ConnectedAppsContext, ToastContext, UserContext} from "../../core/contexts";
import {Skeleton} from "primereact/skeleton";
import {routes} from "../../routes/routes";
import {BeButton, BeInputTextarea} from "../../core/beUi";
import {Question} from "@phosphor-icons/react";
import {OverlayPanel} from "primereact/overlaypanel";
import {ErrorMessage, Form, Formik} from "formik";
import validationSchema from "../utils/validationSchema";
import {useLocation, useNavigate} from "react-router-dom";
import {RouteConfig} from "../../routes/interfaces/routeConfig";
import {useContext, useEffect, useRef, useState} from "react";
import {Sidebar as PrimeSidebar} from 'primereact/sidebar';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../core/state/store";
import {APP_CLOSE_MOBILE_SIDEBAR} from "../../core/state/app/appActions";
import {useMediaQuery} from "react-responsive";
import {UserRole} from "../../core/enums/userRole";
import getPipelineAxios from "../../core/utils/getPipelineAxios";
import {sendEmailApiEmailPost} from "../../clients/pipeline";
import {useToast} from "../../core/hooks";
import {getErrorMessage} from "../../core/utils";
import {FEEDBACK_FROM_ADDRESS, FEEDBACK_SUBJECT, FEEDBACK_TO_ADDRESS} from "../../core/utils/contstants";

const Sidebar = () => {
    const isMobileSidebarOpen = useSelector((state: RootState) => state.app?.isMobileSidebarOpen);
    const {userAttributes, hasActiveSubscription} = useContext(UserContext);
    const {isAllConnected} = useContext(ConnectedAppsContext);
    const [isLoading, setIsLoading] = useState(true);
    const [navLinks, setNavLinks] = useState<RouteConfig[]>([]);
    const [isSending, setIsSending] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const {pathname} = location;
    const overlayPanelRef = useRef<OverlayPanel>(null);
    const dispatch: AppDispatch = useDispatch();
    const toastRef = useContext(ToastContext);
    const { showToast } = useToast(toastRef);

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    const pathMatchCheck = (path: string, pathname: string): boolean => {
        return (path === pathname || pathname.startsWith(`${path}/`));
    }

    const handleFeedbackSend = async(value: any) => {
        const axios = await getPipelineAxios();
        setIsSending(true);
        sendEmailApiEmailPost({
            client: axios,
            body: {
                from_email: FEEDBACK_FROM_ADDRESS,
                to_email: FEEDBACK_TO_ADDRESS,
                subject: `${FEEDBACK_SUBJECT} ${userAttributes?.given_name} ${userAttributes?.family_name}`,
                context: value.message
            }
        }).then(_ => {
            showToast({
                severity: 'success',
                summary: 'success',
                detail: 'Your feedback has been sent successfully!',
            });
            setIsSending(false);
            overlayPanelRef.current?.hide();
        }).catch(error => {
            showToast({
                severity: 'error',
                summary: 'failed',
                detail: getErrorMessage(error),
            });
            setIsSending(false);
        })
    }

    useEffect(() => {
        setIsLoading(!userAttributes?.workspace.role || isAllConnected === null);
    }, [userAttributes, isAllConnected]);

    useEffect(() => {
        const links = routes
            .filter(route => route.name === 'main' && route.routes)
            .flatMap(route => route.routes || [])
            .filter(subRoute => subRoute?.sidebarLink === true && (!subRoute.userRole || subRoute.userRole.includes(userAttributes?.workspace?.role || UserRole.SUPER_ADMIN)));

        setNavLinks(links);
    }, [userAttributes]);

    useEffect(() => {
        dispatch({type: APP_CLOSE_MOBILE_SIDEBAR});
    },[location])

    const content = <nav className="h-full w-auto shrink-0 grow-0 bg-secondary p-4 lg:w-56 lg:p-5">
        <ul className="flex h-full flex-col items-stretch justify-start gap-y-3">
            {isLoading ? (
                <>
                    {navLinks.map((r, i) => <li key={i}>
                        <Skeleton
                            height="3rem"
                            className="bg-secondary-light"
                        />
                    </li>)}

                </>
            ) : (
                navLinks.map(
                    ({name, title, path, icon: Icon, disableIfNotAllConnected, allowWithNoSubscription}) => (
                        <li key={name}>
                            <BeButton
                                disabled={(disableIfNotAllConnected && !isAllConnected) || (!hasActiveSubscription && !allowWithNoSubscription)}
                                className={`${path && pathMatchCheck(path, pathname) ? 'bg-primary font-medium text-secondary hover:bg-primary' : 'bg-transparent font-normal text-white hover:bg-secondary-light'} flex items-center justify-start gap-x-2.5 border-none p-3 text-sm`}
                                onClick={() => path && navigate(path)}
                                fullWidth
                            >
                                {Icon && <Icon size="1.75rem"/>}
                                {title}
                            </BeButton>
                        </li>
                    )
                )
            )}
            <li className="mt-auto">
                <BeButton
                    className="flex w-full items-center justify-start gap-x-2.5 border-none bg-transparent p-3 text-sm font-normal text-white hover:bg-secondary-light"
                    onClick={overlayPanelRef.current?.toggle}
                >
                    <Question size="1.75rem"/>
                    Feedback
                </BeButton>
                <OverlayPanel ref={overlayPanelRef}>
                    <Formik
                        initialValues={{message: ''}}
                        validationSchema={validationSchema}
                        onSubmit={handleFeedbackSend}
                    >
                        {({values, handleChange}) => (
                            <Form>
                                <div className="flex flex-col items-center gap-y-6">
                                    <div className="flex flex-col gap-1">
                                        <label className="text-sm">
                                            Message
                                        </label>
                                        <BeInputTextarea
                                            className="w-full"
                                            placeholder="Write your message..."
                                            name="message"
                                            values={values}
                                            onChange={handleChange}
                                        />
                                        <ErrorMessage name="message"/>
                                    </div>
                                    <BeButton
                                        loading={isSending}
                                        type="submit"
                                        fullWidth
                                    >
                                        Submit
                                    </BeButton>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </OverlayPanel>
            </li>
        </ul>
    </nav>;


    return (
        <>
            { !isDesktopOrLaptop && <PrimeSidebar
                visible={isMobileSidebarOpen}
                onHide={() => dispatch({type: APP_CLOSE_MOBILE_SIDEBAR})}
                content={({closeIconRef, hide}) => (content)}>
            </PrimeSidebar> }
            { isDesktopOrLaptop && content }

        </>);

}
export default Sidebar;