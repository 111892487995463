import AuthLayout from "../layouts/authLayout";
import MainLayout from "../layouts/mainLayout";
import SignInPage from "../pages/auth/signIn/signInPage";
import DashboardPage from "../pages/dashboard/dashboardPage";
import {renderRoutes} from "./renderRoutes";
import {RouteConfig} from "./interfaces/routeConfig";
import LandingPage from "../pages/landing/landingPage";
import {
    IntersectSquare,
    MoneyWavy,
    Speedometer,
    UserFocus,
    Users,
} from '@phosphor-icons/react';
import PlacementsPage from "../pages/placements/placementsPage";
import UsersPage from "../pages/users/usersPage";
import AddUserPage from "../pages/users/add/addUserPage";
import EditUserPage from "../pages/users/edit/editUserPage";
import BillingPage from "../pages/billing/billingPage";
import IntegrationsPage from "../pages/integrations/integrationsPage";
import SignUpPage from "../pages/auth/signUp/signUpPage";
import LandingLayout from "../layouts/landingLayout";
import EmptyLayout from "../layouts/emptyLayout";
import PageNotFound from "../pages/statusPages/pageNotFound";
import {UserRole} from "../core/enums/userRole";
import JobAdderCallback from "../pages/integrations/callbacks/jobAdderCallback";
import BillingVerifyPage from "../pages/billing/verify/billingVerifyPage";
import SelectWorkspacePage from "../pages/auth/selectWorkspace/selectWorkspacePage";
import BillingCancelledSubscriptionPage from "../pages/billing/cancelled/billingCancelledSubscriptionPage";
import ServiceUnavailable from "../pages/statusPages/serviceUnavailable";

export const routes: RouteConfig[] = [
    {
        name: 'landingLayout',
        layout: LandingLayout,
        routes: [
            {
                name: 'landing',
                title: 'Landing',
                component: LandingPage,
                path: '/',
                isPublic: true,
            }
        ]
    },
    {
        name: 'auth',
        layout: AuthLayout,
        routes: [
            {
                name: 'signIn',
                title: 'Sign in',
                component: SignInPage,
                path: '/sign-in',
                isPublic: true,
            },
            {
                name: 'signUp',
                title: 'Sign up',
                component: SignUpPage,
                path: '/sign-up',
                isPublic: true,
            },
            {
                name: 'selectWorkspace',
                title: 'Select Workspace',
                component: SelectWorkspacePage,
                path: '/select-workspace',
                isPublic: true
            }
        ]
    },
    {
        name: 'main',
        layout: MainLayout,
        routes: [
            {
                name: 'dashboard',
                title: 'Dashboard',
                sidebarLink: true,
                component: DashboardPage,
                path: '/dashboard',
                icon: Speedometer,
                disableIfNotAllConnected: true
            },
            {
                name: 'placements',
                title: 'Placements',
                sidebarLink: true,
                component: PlacementsPage,
                path: '/placements',
                icon: UserFocus,
                disableIfNotAllConnected: true
            },
            {
                name: 'users',
                title: 'Users',
                sidebarLink: true,
                component: UsersPage,
                path: '/users',
                icon: Users,
                userRole: [UserRole.ADMIN],
                disableIfNotAllConnected: true,
                routes: [
                    {
                        name: 'add-user',
                        title: 'Add user',
                        component: AddUserPage,
                        path: '/users/add',
                        userRole: [UserRole.ADMIN]
                    },
                    {
                        name: 'edit-user',
                        title: 'Edit user',
                        component: EditUserPage,
                        path: '/users/:id',
                        userRole: [UserRole.ADMIN]
                    },
                ]
            },
            {
                name: 'billing',
                title: 'Billing',
                sidebarLink: true,
                component: BillingPage,
                path: '/billing',
                icon: MoneyWavy,
                userRole: [UserRole.ADMIN],
                disableIfNotAllConnected: true,
                allowWithNoSubscription: true
            },
            {
                name: 'billingVerify',
                title: 'Billing verify',
                sidebarLink: false,
                component: BillingVerifyPage,
                path: '/billing/verify',
                userRole: [UserRole.ADMIN],
                disableIfNotAllConnected: true,
                allowWithNoSubscription: true
            },
            {
                name: 'billingCancelled',
                title: 'Billing cancelled',
                sidebarLink: false,
                component: BillingCancelledSubscriptionPage,
                path: '/billing/cancelled',
                disableIfNotAllConnected: true,
                allowWithNoSubscription: true
            },
            {
                name: 'integrations',
                title: 'Integrations',
                sidebarLink: true,
                component: IntegrationsPage,
                path: '/integrations',
                icon: IntersectSquare,
                userRole: [UserRole.ADMIN]
            }
        ]
    },
    {
        name: 'empty',
        layout: EmptyLayout,
        routes: [
            {
                name: 'jobAdderCallback',
                title: 'JobAdderCallback Callback',
                component: JobAdderCallback,
                path: '/integrations/jobadder',
                allowWithNoSubscription: true
            }
        ]
    },
    {
        name: 'empty',
        layout: EmptyLayout,
        routes: [
            {
                name: 'serviceUnavailable',
                title: 'Service Unavailable',
                component: ServiceUnavailable,
                path: '/service-unavailable',
                isPublic: true,
            },
            {
                name: '404',
                title: '404 - Not found',
                component: PageNotFound,
                path: '*',
                isPublic: true,
            }
        ]
    },
];

export const Routes = renderRoutes(routes);