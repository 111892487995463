import {ArrowsClockwise, CurrencyDollarSimple, Funnel, Invoice, Percent, WaveTriangle} from '@phosphor-icons/react';
import {Avatar} from 'primereact/avatar';
import {Column} from 'primereact/column';
import {DataTable, DataTableStateEvent} from 'primereact/datatable';
import {useEffect, useState} from 'react';
import {Line} from 'react-chartjs-2';
import {
    CategoryScale,
    Chart,
    ChartData,
    ChartOptions,
    DefaultDataPoint,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';


import DashboardLoading from './dashboardLoading';
import {withConnectedApps} from "../../core/hoc";
import {BeBadge, BeButton} from "../../core/beUi";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../core/hooks";
import FrequencyPicker from "./filters/frequencyPicker/frequencyPicker";
import CardWidget from "./widgets/CardWidget";
import {AppDispatch, RootState} from "../../core/state/store";
import {useDispatch, useSelector} from "react-redux";
import {
    getBillingLineGraphData,
    getBillingSummary,
    getLatestPlacements
} from "../../core/state/dashboard/dashboardEffects";
import {FrequencyEnum} from "./enums/frequencyEnum";
import { DateTime } from "luxon";
import {Chip} from "primereact/chip";
import {WorkspaceUser} from "../../core/contexts/User/types";
import UserPicker from "./filters/usersPicker/usersPicker";
import RolePicker from "./filters/rolePicker/rolePicker";

Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

Tooltip.positioners.offsetPosition = (activeItems) => {
    const isTop = activeItems[0]?.element.y > 120;
    return {
        x: activeItems[0]?.element.x,
        y: isTop
            ? activeItems[0]?.element.y - 20
            : activeItems[0]?.element.y + 20,
        yAlign: isTop ? 'bottom' : 'top',
    };
};

const DashboardPage = () => {

    const isLoadingLatestPlacements = useSelector((state: RootState) => state.dashboard?.isLoadingLatestPlacements);
    const totalLatestPlacements = useSelector((state: RootState) => state.dashboard?.totalLatestPlacements);
    const latestPlacements = useSelector((state: RootState) => state.dashboard?.latestPlacements);
    const billingSummary = useSelector((state: RootState) => state.dashboard?.billingSummary);
    const lineGraphData = useSelector((state: RootState) => state.dashboard?.lineGraphData);
    const [filters, setFilters] = useState({
        frequency: FrequencyEnum.Quarterly,
        user: 0,
        role: ''
    });
    const [cards, setCards] = useState<any[]>([]);
    const [sorting, setSorting] = useState<any>({
        field: 'startDate',
        order: -1,
    });
    const [pageSize] = useState(10);
    const computedStyles = getComputedStyle(document.documentElement);
    const twBlue = computedStyles.getPropertyValue('--bluegray-900');
    const interFont = computedStyles.getPropertyValue('--font-inter');
    const navigate = useNavigate();
    const { userAttributes } = useAuth();
    const dispatch: AppDispatch = useDispatch();

    const [chartData, setChartData] = useState<
        ChartData<'line', DefaultDataPoint<'line'>, string> | null
    >(null);
    const [chartOptions] = useState<ChartOptions<'line'>>({
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        interaction: {
            intersect: false,
            axis: 'xy',
            mode: 'nearest',
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                position: 'offsetPosition',
                backgroundColor: '#FFF',
                bodyAlign: 'center',
                displayColors: false,
                cornerRadius: 8,
                bodyColor: '#0A112F',
                padding: 12,
                titleFont: {
                    family: interFont,
                    size: 14,
                    weight: 'bold',
                },
                titleMarginBottom: 10,
                titleAlign: 'center',
                titleColor: '#70707A',
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
            y: {
                display: false,
            },
        },
    });

    const handleSortPlacements = (event: DataTableStateEvent) => {
        setSorting({
            field: event.sortField,
            order: event.sortOrder
        });
    }

    useEffect(() => {
        const user = userAttributes?.workspaceUsers.find(u => u.id === filters.user);
        const userId = user?.astuteId || '';
        dispatch(getLatestPlacements(pageSize, sorting.field, sorting.order === -1 ? 'desc' : 'asc', userId));
    }, [pageSize, sorting, filters]);

    useEffect(() => {
        const user = userAttributes?.workspaceUsers.find(u => u.id === filters.user);
        const userId = user?.astuteId || '';
        dispatch(getBillingSummary(filters.frequency, userId));
        dispatch(getBillingLineGraphData(filters.frequency, userId));
    },[filters]);

    useEffect(() => {
        if(billingSummary) {
            setCards([
                {id: 1, label: 'Your threshold', value: `$${billingSummary.threshold.toLocaleString()}`, icon: WaveTriangle},
                {id: 2, label: 'Your bonus', value: `${billingSummary.bonus.toLocaleString()}%`, icon: Percent},
                {id: 3, label: 'Your billings', value: `$${billingSummary.total.toLocaleString()}`, icon: Invoice},
                {id: 4, label: 'Bonus amount', value: `$${billingSummary.bonus_amount.toLocaleString()}`, icon: CurrencyDollarSimple},
            ]);
        }
    }, [billingSummary]);

    useEffect(() => {

        const monthNames = [
            "January", "February", "March", "April",
            "May", "June", "July", "August",
            "September", "October", "November", "December"
        ];

        const getChartData = () => {
            const currentYear = DateTime.now().year;

            // Filter data for the current year if the frequency is not yearly
            const filteredData =
                filters.frequency !== FrequencyEnum.Yearly
                    ? lineGraphData.filter((data) => data.year === currentYear)
                    : lineGraphData;

            switch (filters.frequency) {
                case FrequencyEnum.Monthly:
                    // Ensure all months (1-12) are included, even those with no data
                    const months = Array.from({ length: 12 }, (_, i) => i + 1);
                    const monthlyData = months.map((month) => {
                        const dataForMonth = filteredData.find((data) => data.month === month);
                        return dataForMonth ? dataForMonth.total_billings : 0;
                    });

                    return {
                        labels: months.map((month) => monthNames[month - 1]),
                        data: monthlyData,
                    };

                case FrequencyEnum.Quarterly:
                    // Ensure all quarters (Q1-Q4) are included, even those with no data
                    const quarters = [1, 2, 3, 4];
                    const quarterlyData = quarters.map((quarter) => {
                        const dataForQuarter = filteredData.filter((data) => data.quarter === quarter);
                        return dataForQuarter.length > 0
                            ? dataForQuarter.reduce((acc, curr) => acc + curr.total_billings, 0)
                            : 0;
                    });

                    return {
                        labels: quarters.map((quarter) => `Q${quarter}`),
                        data: quarterlyData,
                    };

                case FrequencyEnum.Yearly:
                    return {
                        labels: Array.from(new Set(filteredData.map((data) => data.year.toString()))),
                        data: Object.values(
                            filteredData.reduce((acc: any, curr) => {
                                acc[curr.year] = (acc[curr.year] || 0) + curr.total_billings;
                                return acc;
                            }, {})
                        ),
                    };

                default:
                    return { labels: [], data: [] };
            }
        };

        const { labels, data }: any = getChartData();

        setChartData({
            labels,
            datasets: [
                {
                    label: 'Billings',
                    data,
                    fill: false,
                    borderColor: twBlue,
                    tension: 0.5,
                    pointStyle: 'circle',
                    pointBackgroundColor: 'transparent',
                    pointBorderWidth: 10,
                    pointBorderColor: 'transparent',
                    pointRadius: 10,
                    pointHoverBackgroundColor: twBlue,
                    pointHoverBorderColor: '#FFF',
                    pointHoverBorderWidth: 10,
                    pointHoverRadius: 10,
                },
            ],
        });
    }, [lineGraphData, filters]);

    return (
        <div className="flex flex-col gap-y-6">
            <div
                className="flex flex-col gap-y-6 sm:flex-row sm:items-center sm:justify-between sm:gap-x-6 sm:gap-y-0 ">
                <div>
                    <div className="flex flex-col gap-y-1 sm:flex-row sm:gap-x-2">
                        <h2 className="text-4xl font-bold text-black">Welcome,</h2>
                        <h2 className="text-4xl font-medium text-black">{userAttributes?.given_name} {userAttributes?.family_name}</h2>
                    </div>
                    <h6 className="font-normal text-gray-600">Your dashboard to track and review recruitment
                        progress</h6>
                </div>

                <div>
                    <Chip template={
                        <div className="flex gap-x-2 flex-wrap">
                            <span className="font-medium">Last sync:</span>
                            <span className="font-normal">{billingSummary?.loaded_at}</span>
                        </div>}/>
                </div>

                <div className="flex flex-col items-center gap-y-3 sm:flex-row sm:justify-between sm:gap-x-1 md:hidden lg:flex">
                    <FrequencyPicker frequency={filters.frequency} onChange={(f: FrequencyEnum) => setFilters({...filters, frequency: f})}/>
                    <UserPicker user={filters.user} onChange={(u: number) => setFilters({...filters, user: u})}/>
                    <RolePicker role={filters.role} onChange={(r: string) => setFilters({...filters, user: 0, role: r})}/>
                </div>
            </div>
            <div className="hidden md:flex md:flex-row md:items-center md:gap-x-1 lg:hidden">
                <FrequencyPicker frequency={filters.frequency} onChange={(f: FrequencyEnum) => setFilters({...filters, frequency: f})}/>
                <UserPicker user={filters.user} onChange={(u: number) => setFilters({...filters, user: u})}/>
                <RolePicker role={filters.role} onChange={(r: string) => setFilters({...filters, user: 0, role: r})}/>
            </div>
            <div className="flex flex-wrap justify-stretch gap-6">
                {cards.map((card) => (
                    <div key={card.id} className="flex flex-1 basis-1/5 flex-col items-start justify-start gap-y-6">
                        <CardWidget label={card.label} value={card.value} icon={card.icon}/>
                    </div>
                ))}
            </div>
            <div
                className="rounded-2xl border border-solid bg-gradient-to-t from-primary from-2% via-primaryLight via-6% to-white to-85% p-4">
                <div className="flex flex-col gap-y-2">
                    <div className="flex items-center justify-between gap-x-6">
                        <div className="flex flex-row items-center gap-x-2">
                            <Avatar

                                shape="circle"
                                className="bg-primary group-hover:bg-primary-light"
                            >
                                <Invoice size="1.4rem"/>
                            </Avatar>
                            <span>Your billings for {filters.frequency === FrequencyEnum.Yearly ? `2023 through to ${DateTime.now().year}` : DateTime.now().year}</span>
                        </div>
                        <div className="flex gap-x-2">
                            {/*<BeBadge*/}
                            {/*    value="+23%"*/}
                            {/*    severity="success"*/}
                            {/*    className="text-base font-medium"*/}
                            {/*/>*/}
                            {/*<span className="font-light">vs previous quarter</span>*/}
                        </div>
                    </div>
                    <div className="flex items-center justify-between gap-x-6">
                        <span className="text-4xl font-medium text-secondary">
                            {cards && cards.length && cards[2].value}
                        </span>

                    </div>
                </div>
                <div className="min-h-48">
                    {!!chartData && <Line data={chartData} options={chartOptions}/>}
                </div>
            </div>
            <div className="rounded-2xl bg-white p-6 shadow-sm">
                <div className="mb-5 flex flex-col gap-y-6 sm:flex-row sm:items-center sm:justify-between sm:gap-x-6">
                    <div className="flex flex-row gap-x-2">
                        <div>
                            <h2 className="text-2xl font-bold text-secondary">Latest placements</h2>
                            <h6 className="font-normal text-gray-600">{totalLatestPlacements} new placements as of
                                the {billingSummary?.loaded_at}</h6>
                        </div>
                    </div>
                    <BeButton
                        variant="outlined"
                        size="sm"
                        className="rounded-full"
                        onClick={() => navigate('/placements')}
                    >
                        View all
                    </BeButton>
                </div>
                <div className="overflow-x-auto scrollbar-thin">
                    <div className="inline-block min-w-full align-middle">
                        <DataTable
                            value={latestPlacements}
                            rows={10}
                            sortField={sorting.field} sortOrder={sorting.order}
                            tableClassName="min-w-full max-w-max"
                            sortMode="single"
                            lazy={true}
                            onSort={handleSortPlacements}
                        >
                            <Column
                                field="candidateName"
                                header="Candidate Name"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal"
                                sortable
                            />
                            <Column
                                field="jobTitle"
                                header="Job Title"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal"
                                sortable
                            />
                            <Column
                                field="company"
                                header="Company"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal"
                                sortable
                            />
                            <Column
                                field="type"
                                header="Type"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                                sortable
                            />
                            <Column
                                field="recruiter"
                                header="Recruiter"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal"
                                sortable
                            />
                            <Column
                                field="totalAmount"
                                header="Total Amount"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                                sortable
                            />
                            <Column
                                field="split"
                                header="Split"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                                sortable
                            />
                            <Column
                                field="startDate"
                                header="Start Date"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                                sortable
                            />
                            <Column
                                field="invoiceId"
                                header="INV-#"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                                sortable
                            />
                            <Column
                                field="invoiceDue"
                                header="Invoice Due"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                                sortable
                            />
                            <Column
                                field="invoiceStatus"
                                header="Invoice Status"
                                headerClassName="text-brandGray-1 text-sm font-bold whitespace-nowrap bg-white"
                                bodyClassName="text-secondary text-sm font-normal whitespace-nowrap"
                                sortable
                            />
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withConnectedApps(DashboardPage, DashboardLoading);
