
import {UnknownAction} from "redux";
import * as actionTypes from "../actions";
import {produce} from "immer";
import {AppState} from "./interface/appState";


const initialState: AppState = {
    isMobileSidebarOpen: false
};

const appReducer = (state: AppState = initialState, action: UnknownAction): AppState => {
    switch (action.type) {
        case actionTypes.APP_OPEN_MOBILE_SIDEBAR:
            return produce(state, draft => {
                draft.isMobileSidebarOpen = true;
            });
        case actionTypes.APP_CLOSE_MOBILE_SIDEBAR:
            return produce(state, draft => {
                draft.isMobileSidebarOpen = false;
            });
        default:
            return state;
    }

}
export default appReducer;